import classes from "./Introduction.module.css";
import React, { useEffect, useContext, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import MainCarousel from "../Carousel/Carousel";

const INTRODUCTION_IMAGES = [
  images.nail_shop02,
  images.nail3,
  images.nail_shop03,
  images.nail2,
  images.nail6,
  images.nail_shop05,
  images.nail1,
];

const Introduction = (props) => {
  const { introductionRef } = props || {};
  const isMobile = useCheckIsMobile();

  return (
    <div ref={introductionRef} className={classes.introduction}>
      <div className={classes.introHeader}>
        <h2 className={classes.cursiveText}>{NAIL_SHOP_INFO.name}</h2>
      </div>
      <div className={classes.rowWrapper}>
        <MainCarousel interval={8000} renderArrowPrev={() => null} renderArrowNext={() => null}>
          {INTRODUCTION_IMAGES.map((img) => {
            return (
              <div key={`${img}`} className={classes.imgContainer}>
                <img alt={"Intro"} src={img} className={classes.introImg} />
              </div>
            );
          })}
        </MainCarousel>

        {/* <div className={classes.imgContainer}>
                <img alt={"Intro"} src={images.nail2} className={classes.introImg} />
              </div> */}

        <div className={classes.introTextContainer}>
          <div>
            <h1>{"WHERE BEAUTY MEET RELAXATION!"}</h1>
            <h3 className={classes.greyIntroTextContainer}>
              Camden Nails & Beauty - Your bespoke nails art in London
            </h3>
          </div>
          <p className={classes.greyIntroTextContainer}>
            Welcome to Camden Nails & Beauty, your ultimate destination at 73
            Parkway, London. We offer a perfect blend of expert techniques and a
            friendly environment, ensuring that every visit leaves you feeling
            pampered and your nails looking their best. Whether you're after a
            timeless manicure or something more personalized with custom nail
            art, our skilled team is here to bring your ideas to life.
          </p>
          <p className={classes.greyIntroTextContainer}>
            Choose Camden Nails & Beauty for your next nail appointment, and
            discover why we're quickly becoming a favorite in London. Our
            extensive range of services is crafted to keep your nails both
            beautiful and healthy every time you visit.
          </p>
          <BookNowBtn />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
