import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./AboutUs.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { ReactPhotoCollage } from "react-photo-collage";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const AboutUs = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"About us"}</title>
        <meta name="about-us" content="About us" />
      </Helmet>
      <Header selected={"about-us"} />

      <h1>{"\n\n"}</h1>

      <ReactPhotoCollage
        width={"100%"}
        height={
          isMobile
            ? [
                "28rem",
                "23rem",
                "35rem",
                "28rem",
                "20rem",
                "28rem",
                "30rem",
                "35rem",
                "28rem",
              ]
            : ["48rem", "38rem", "32rem", "48rem", "44rem", "34rem"]
        }
        layout={isMobile ? [1, 2, 1, 2, 3, 1, 2, 3, 2] : [3, 2, 3, 4, 1, 3]}
        photos={[
          { source: images.nail1 },
          { source: images.nail_shop05 },
          { source: images.nail5 },
          { source: images.nail2 },
          { source: images.nail_shop03 },
          { source: images.nail_shop02 },
          { source: images.nail3 },
          { source: images.nail6 },
          { source: images.nail_shop01 },
          { source: images.nail7 },
          { source: images.nail_shop08 },
          { source: images.nail_shop07 },
          { source: images.nail8 },
          { source: images.nail4 },
          { source: images.nail_shop06 },
          { source: images.nail_shop04 },
        ]}
        showNumOfRemainingPhotos
      />
      <h1>Gift Voucher Available</h1>
      <BookNowBtn style={classes.bookNowBtnStyle} />

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default AboutUs;
