import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { SERVICES } from "../../utils/Const";
import { images } from "../../assets/img";
import RemoteConfig from "../../services/RemoteConfig";
import BookNowBtn from "../BookNowBtn";
import '../../index.css'

const ServiceItem = (item, index, onPress) => {
  const { name, image, description } = item || {};
  return (
    <div
      onClick={onPress?.(item)}
      key={`${name + index}`}
      className={`${classes.serviceItem}`}
    >
      <div className={`${classes.roundDiv} linear-gradient-bg`}>
        <img alt={"nail"} src={image} />
      </div>

      <h3>{name}</h3>
      <h4 className={classes["change-color-on-hover"]}>{description}</h4>
    </div>
  );
};

const Services = (props) => {
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [pricingImg,setPricingImg] = useState(images.pricing);
  const navigate = useNavigate();

  useEffect(() => {
    const introduction_image = RemoteConfig.getConfigValue(
      "pricing_image"
    );

    introduction_image && setPricingImg(introduction_image)

  }, [isFetchActivateRemoteConfig]);

  const onPress = (item) => () => {
    // navigate(`/service-details/${item?.id}`, {state: {...item}});
  };

  return (
    <div ref={props?.serviceRef} className={classes.wrapper}>
      <h1>Our Services</h1>

            <div className={classes.container}>
                {Helpers.isArrayAvailable(SERVICES)
                    ? SERVICES.map((item, index) => {
                        return ServiceItem(item, index, onPress);
                    })
                    : null}
            </div>

            <BookNowBtn style={classes.priceListBtn} onClick={()=> navigate('/services')} title={'VIEW PRICING'}/>
    </div>
  );
};

export default Services;
