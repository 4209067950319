import React from "react";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { MENU_HEADER, NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import { t } from "../../locales/i18n";
import BookNowBtn from "../BookNowBtn";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import Menu from "../Menu/Menu";
import { menuHeaderHandler } from "../../navigation/MenuHeaderHandler";

const Header = (props) => {
  const {
    introductionRef,
    contactsRef,
    serviceRef,
    onPressMainLogo,
    selected,
  } = props || {};
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  const renderLogo = () => {
    return (
      <img
        onClick={onPressMainLogo}
        className={`${classes.introLogo} ${
          onPressMainLogo ? classes.clickableLogo : null
        }`}
        alt={"main logo"}
        src={images.main_logo}
      />
    );
  };

  return (
    <div className={classes.introHeader}>
      <img
        className={classes.headerBannerImg}
        alt={"Primary Banner"}
        src={images.header_primary02}
      />

      {/* {!isMobile && renderLogo()} */}

      <div className={classes.headerIntroContainer}>
        <h2>
          {isMobile ? NAIL_SHOP_INFO.name : "Camden Nails\n           & Beauty"}
        </h2>
        <h3>
          {
            `Welcome to Camden Nails & Beauty, your trusted nail salon${isMobile? ' ' : '\n'}at 73 Parkway, London. We pride ourselves on delivering exceptional nail care, offering everything from chic manicures to artistic nail designs.\n\nVisit us today, and let your nails dazzle!`
          }
        </h3>
      </div>

      {isMobile ? (
        <Menu
          selected={selected}
          introductionRef={introductionRef}
          serviceRef={serviceRef}
          contactsRef={contactsRef}
        />
      ) : (
        <div className={classes.menuItem}>
          {MENU_HEADER.map((item, index) => {
            const { name, navigationKey } = item || {};
            return (
              <h4
                key={`${name}_${index}`}
                onClick={() => menuHeaderHandler(navigationKey, navigate)}
                className={
                  selected === navigationKey ? classes.selectedText : ""
                }
              >
                {name}
              </h4>
            );
          })}

          {/* <h4
            onClick={() => navigate("/home")}
            className={selected === "home" ? classes.selectedText : ""}
          >
            {"Home"}
          </h4>
          <h4
            className={selected === "about-us" ? classes.selectedText : ""}
            onClick={() => navigate("/about-us")}
          >
            {"About us"}
          </h4>
          <h4
            className={selected === "services" ? classes.selectedText : ""}
            onClick={() => navigate("/services")}
          >
            {t("header.service")}
          </h4>
          <h4 onClick={() => window.open(NAIL_SHOP_INFO.bookingUrl)}>
            {"Booking"}
          </h4>
          <h4 onClick={contactPress || scrollToSection(contactsRef)}>
            {t("header.contact")}
          </h4>
          <h4
            className={selected === "policy" ? classes.selectedText : ""}
            onClick={() => navigate("/policy")}
          >
            {"Policy"}
          </h4> */}
        </div>
      )}

      {/* {isMobile && renderLogo()} */}
    </div>
  );
};

export default Header;
