export const images = {
    flagUk: require('./flag-uk.png'),
    flagVn: require('./flag-vn.png'),
    fbIcon: require('./fb-icon.png'),
    messengerIcon: require('./messenger-icon.png'),
    instaIcon: require('./insta-icon.png'),
    pricing: require('./pricing.jpg'),
    nailService: require('./nail-service.png'),
    nailService2: require('./nail-service2.png'),
    nailService3: require('./nail-service3.png'),
    nailService4: require('./nail-service4.png'),
    leftArrow: require('./left-arrow.png'),
    main_logo:  require('./main-logo.png'),
    header_primary: require('./header_primary.jpg'),
    header_primary02: require('./header_primary02.jpg'),
    nail1: require('./nail1.jpg'),
    nail2: require('./nail2.jpg'),
    nail3: require('./nail3.jpg'),
    nail4: require('./nail4.jpg'),
    nail5: require('./nail5.jpg'),
    nail6: require('./nail6.jpg'),
    nail7: require('./nail7.jpg'),
    nail8: require('./nail8.jpg'),
    nail_shop01: require('./nail-shop01.jpg'),
    nail_shop02: require('./nail-shop02.jpg'),
    nail_shop03: require('./nail-shop03.jpg'),
    nail_shop04: require('./nail-shop04.jpg'),
    nail_shop05: require('./nail-shop05.jpg'),
    nail_shop06: require('./nail-shop06.jpg'),
    nail_shop07: require('./nail-shop07.jpg'),
    nail_shop08: require('./nail-shop08.jpg'),
};
